import React from 'react';
import './body.css';
import Plans from '../../views/plans/Plans';
import Consumers from '../../views/consumers/Consumers';
import Services from '../../views/services/Services';
import SellPlan from '../../views/sellPan/SellPlan';

function Body(props:  {view: string}) {

  return (
    <>
      <div className='Body'>
        {props.view ==='apikey' && <Consumers />}
        {props.view ==='gallery' && <Plans />}
        {props.view ==='services' && <Services />}
        {props.view ==='sell' && <SellPlan />}
      </div>
    </>
  );
}

export default Body;
