export const MockPlans: Plans[] = [
  {
    title: 'Plano 1 ',
    description: 'Cabelo + Barba',
    amount: 45.00,
    status: 'Ativo'
  }
]

interface Plans {
  title: string,
  description: string,
  amount: number,
  status: string
}