import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Header from '../../modules/header/header';
import { GenerateUUID } from '../../helpers/GenerateUUID';

import { Button, TextField, Typography } from '@mui/material';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import EngineeringIcon from '@mui/icons-material/Engineering';

import './Login.css';
import 'react-toastify/dist/ReactToastify.css';


function Login() {
  const generateToken = new GenerateUUID()

  const envUser = process.env.REACT_APP_USER;
  const envPassword = process.env.REACT_APP_PASSWORD;

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  async function validateLogin(){
    if (user !== envUser || password !== envPassword){
      toast.error('User or Password incorrects.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
      setUser('');
      setPassword('');
    }else{
      await toast.success('Log in approved!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
      const hash = await generateSessionHash()
      await localStorage.setItem('stbs', hash)
      navigate("/mvp")
    }
  }

  async function generateSessionHash(){
    const UUID = await generateToken.generatev1()
    return UUID
  }

  return (
    <div className="Login">
      <Header />
      <div className="LoginBottom">
        <EngineeringIcon sx={{fontSize: '200px', marginTop: '10%'}} />
        <Typography variant="h4" gutterBottom>
          Website under maintenance.
        </Typography>
        {/* <TextField
          className='fields'
          required
          id="filled-required"
          label="User"
          variant="filled"
          value={user}
          onChange={(e)=>setUser(e.target.value)}
          sx={{marginBottom: '2%', marginTop: '10%'}}
          autoFocus
        />
        <TextField
          size="small"
          className='fields'
          required
          id="filled-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="filled"
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
          sx={{marginBottom: '2%'}}
          autoFocus
        />
        <Button 
          className='fields'
          variant="contained"
          onClick={validateLogin}
        >
          Log in
        </Button>
        <ToastContainer /> */}
      </div>
    </div>
  );
}

export default Login;
