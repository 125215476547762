export class ConsumerService {
  private readonly URL_BASE = process.env.REACT_APP_URL_BASE!;
  private readonly TOKEN = process.env.REACT_APP_TOKEN!;
  
  create(input: Input) {
    const options = {
      method: 'post',
      header: {
        'Content-Type': 'application/json',
        'Authorization': this.TOKEN
      },
      body: JSON.stringify(input)
    }

    try{
      fetch(`${this.URL_BASE}/plans`, options).then((res)=>{
        console.log('RES # ', res)
      })
  
    }catch(error: any){
  
    }
    
  }

  async getById(planId: string){
    const options = {
      method: 'get',
      header: {
        'Content-Type': 'application/json',
        'Authorization': this.TOKEN
      },
      
    }

    try{
      fetch(`${this.URL_BASE}/plans/${planId}`, options).then((res)=>{
        console.log('RES # ', res)
      })
  
    }catch(error: any){
  
    
    }
  }

  delete(consumerId: string){
    const token = '';
    const options = {
      method: 'delete',
      header: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
    }

    try{
      fetch(`${this.URL_BASE}/consumer/${consumerId}`, options).then((res)=>{
        console.log('RES # ', res)
      })
  
    }catch(error: any){
  
    
    }
  }
}

type Input = {
  name: string;
  accountName: string;
  searchEnabled: boolean;
}