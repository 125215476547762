export const MockConsumers: Consumers[] = [
  {
    name: 'Vinicius teste',
    email: 'vinicius@teste.com',
    phoneNumber: '0000000000',
  }
]

interface Consumers {
  name: string,
  email: string,
  phoneNumber: string
}