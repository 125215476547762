import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GroupIcon from '@mui/icons-material/Group';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Logout from '@mui/icons-material/Logout';
import './sidebar.css';
import { useNavigate } from 'react-router-dom';

function SideBar(props: {navigateOnPages: any}) {
  const navigate = useNavigate();

  function updatePage (page: string) {
    props.navigateOnPages(page)
  }

  async function logOff () {
    await localStorage.removeItem('stbs')
    navigate("/mvp")
  }

  return (
    <>
    <div className='sidebar'>
        <List>          
          <ListItem disablePadding>

            <ListItemButton onClick={()=>updatePage('apikey')}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={'Consumers'} />
            </ListItemButton>

          </ListItem>
          <ListItem disablePadding>
            
            <ListItemButton onClick={()=>updatePage('gallery')}>
              <ListItemIcon>
                <ContentCopyIcon />
              </ListItemIcon>
              <ListItemText primary={'Plans'} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={()=>updatePage('services')}>
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary={'Services'} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={()=>updatePage('sell')}>
              <ListItemIcon>
                <ShoppingBagIcon />
              </ListItemIcon>
              <ListItemText primary={'Sell Plan'} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={logOff}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={'Log out'} />
            </ListItemButton>
          </ListItem>

        </List>
      </div>
    </>
  );
}

export default SideBar;
