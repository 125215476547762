import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';

export class ValidateSession {
 
  async validate(hash: string){
    return this.uuidValidateV4(hash);
  }

  private async uuidValidateV4(uuid: string) {
    return uuidValidate(uuid) && uuidVersion(uuid) === 1;
  }
}