import React, { useState } from 'react';
import './Consumers.css';
import { Button, TextField } from '@mui/material';
import TableComponent from '../../components/table/Table';
import { MockConsumers } from '../../helpers/mock/consumers';
import { ToastContainer, Bounce, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { InputMask } from '@react-input/mask';

function Consumers() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [data, ] = useState(MockConsumers);

  const navigate = useNavigate();
  const emailRegex = /\S+@\S+\.\S+/
  const phoneRegex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
  
  async function validationData(){
    if(name === '' || email ==='' || phoneNumber ===''){
      return false;
    }else{
      return true
    }
  }

  async function validateEmail (){
    return emailRegex.test(email)
  }

  async function validatePhone () {
    return phoneRegex.test(phoneNumber)
  }

  async function saveConsumer (){
    const validation = await validationData()
    const emailValidated = await validateEmail()
    const phoneValidated = await validatePhone()

    if(validation && emailValidated && phoneValidated){
      await data.push({name, email, phoneNumber})
      setName('')
      setEmail('')
      setPhoneNumber('')
      navigate("/mvp")
    }else{
      toast.error('Data incorrect.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
    }
  }

  async function deleteConsumer(index: number){
    await data.splice(index, 1)
    navigate("/mvp")
  }

  return (
    <>
      <div className='Consumers'>
        <div className='ConsumersInput'>
          <TextField 
            id="filled-basic" 
            label="Name" 
            variant="filled" 
            value={name}
            onChange={(e)=>{setName(e.target.value)}} 
            margin="normal" 
          />
          <TextField 
            id="filled-basic" 
            label="e-mail" 
            variant="filled" margin="normal"
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}} 
            
          />
          <InputMask 
            placeholder='Phone number' 
            className='ConsumerInputMask' 
            mask="(__) _____-____" 
            replacement={{ _: /\d/ }} 
            onChange={(e)=>{setPhoneNumber(e.target.value)}}
            value={phoneNumber}
          />
          <Button variant="outlined" onClick={()=>saveConsumer()}>Save</Button>
        </div>
        <div className='ConsumersInput'>
          <TableComponent data={data} deleteData={deleteConsumer}/>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Consumers;