import React, { useState } from 'react';
import './Plans.css';
import { Button, Divider, FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ConsumerService } from '../../services/ConsumerService';
import TableComponent from '../../components/table/Table';
import { useNavigate } from 'react-router-dom';
import { MockPlans } from '../../helpers/mock/plans';
import { ToastContainer, Bounce, toast } from 'react-toastify';

function Plans() {
  const [status, setStatus] =  useState('true');
  const [title, setTitle] = useState('');
  const [searchTitle, setSearchTitle] = useState('')
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [validation, setValidation] = useState(false)
  const [data, ] = useState(MockPlans);

  const consumerService = new ConsumerService()
  const navigate = useNavigate();

  function updateStatus (value:any){
    setStatus(value)
  }

  async function getPlans(){
    const response = await consumerService.getById(title);
  }

  async function validateSearch(titleSearch: string){
    if(titleSearch === ''){
      setValidation(true)
      setSearchTitle('')
    }else{
      setValidation(false)
      setSearchTitle(titleSearch)
    }
  }

  function validatePlan(){
    if(title === ''){
      setValidation(false)
      setTitle('')
    }else{
      setValidation(true)
      setTitle(title)
    }
  }

  async function deletePlan(index: number){
    await data.splice(index, 1)
    navigate("/mvp")
  }

  async function savePlan (){
    await validatePlan()
    if(validation){
      await data.push({title, description, amount, status})
      setTitle('')
      setDescription('')
      setAmount(0)
      navigate("/mvp")
    }else{
      toast.error('Data incorrect.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });
    }
  }

  return (
    <>
      <div className='Plans'>
        <div className='PlansInput'>
          {!validation &&
            <TextField  
              id="filled-normal" 
              label="Plans" 
              variant="filled" 
              margin="normal" 
              onChange={(e)=>validateSearch(e.target.value)} 
              value={searchTitle}
              autoFocus/>
          }
          {validation &&
            <TextField 
              error 
              id="filled-error" 
              label="Plans" 
              variant="filled" 
              margin="normal" 
              onChange={(e)=>validateSearch(e.target.value)} 
              helperText="Valor inválido." 
              value={searchTitle}
              autoFocus/>
          }
          <Button variant="outlined" sx={{marginBottom: '10%'}} onClick={getPlans}>Search Plans</Button>
        </div>
        <Divider />
        <div className='PlansBody'>
          <div className='PlansInput'>
            <TextField 
              id="filled-basic" 
              label="Title" 
              variant="filled" 
              margin="normal" 
              onChange={(e)=> setTitle(e.target.value)}
              value={title}
            />
            <TextField 
              id="filled-basic" 
              label="Description" 
              variant="filled" 
              margin="normal" 
              onChange={(e)=> setDescription(e.target.value)}
              value={description}
            />
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
              <Input
                id="standard-adornment-amount"
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                onChange={(e)=> setAmount(parseInt(e.target.value))}
                value={amount}
              />
            </FormControl>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <FormControl variant="filled" sx={{ mb: 2, minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Searc Enabled"
                onChange={(e) => updateStatus(e.target.value)}
              >
                <MenuItem value={'true'}>Ativo</MenuItem>
                <MenuItem value={'false'}>Inativo</MenuItem>
              </Select>
            </FormControl>
            <Button variant="outlined" onClick={()=>savePlan()}>Create Plan</Button>
          </div>
          <div className='PlansTable'>
            <TableComponent data={data} deleteData={deletePlan}/>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Plans;
