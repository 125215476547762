import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';


function TableComponent(props: {data: any, deleteData: any}) {
  const rows = props.data;
  const coll = rows[0] ? Object.keys(rows[0]) : []

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function deleteRow(index: number){
    props.deleteData(index)
  }
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            { rows[0] &&
              Object.keys(rows[0]).map((data)=>{
                return <StyledTableCell  align="center">{data}</StyledTableCell >
              })
              
            }
            {rows[0] &&<StyledTableCell  align="center">Options</StyledTableCell >}
            {
              !rows[0] &&
              <StyledTableCell  align="center">Não há registros.</StyledTableCell >
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any, index: number) => (
            <StyledTableRow key={row.email}>
              {
                coll[0] && coll.map((data)=>{
                  return <TableCell align="center">{data === 'amount' ? 'R$ ' + row[data].toFixed(2)  : row[data]}</TableCell>
                })
              }
              <TableCell align="center" >
              <Tooltip title="Delete" onClick={()=>deleteRow(index)}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              </TableCell>
            </StyledTableRow >
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableComponent;