import React, { useState } from 'react';
import './SellPlan.css';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ConsumerService } from '../../services/ConsumerService';

function SellPlan() {
  const [searchEnabled, setSearchEnabled] =  useState();
  const [service, setService] = useState('');
  const [validationGallery, setValidationGallery] = useState(false)

  const sellPlanService = new ConsumerService()

  function updateSearchEnabled (value:any){
    setSearchEnabled(value)
  }

  async function getServices(){
    const response = await sellPlanService.getById(service);
  }

  function validateGalleryId(service: string){
    if(service === ''){
      setValidationGallery(true)
      setService('')
    }else{
      setValidationGallery(false)
      setService(service)
    }
  }

  return (
    <>
      <div className='SellPlan'>
        <div className='SellPlanInput'>
          {!validationGallery &&
            <TextField  
              id="filled-normal" 
              label="Sell Plan Name" 
              variant="filled" 
              margin="normal" 
              onChange={(e)=>validateGalleryId(e.target.value)} 
              value={service}
              autoFocus/>
          }
          {validationGallery &&
            <TextField 
              error 
              id="filled-error" 
              label="Sell Plan Name" 
              variant="filled" 
              margin="normal" 
              onChange={(e)=>validateGalleryId(e.target.value)} 
              helperText="Valor inválido." 
              value={service}
              autoFocus/>
          }
          <Button variant="outlined" sx={{marginBottom: '10%'}} onClick={getServices}>Search Sell Plan</Button>
        </div>
        <Divider />
        <div className='SellPlanInput'>
          <TextField id="filled-basic" label="Plan" variant="filled" margin="normal" />
          <TextField id="filled-basic" label="Consumer" variant="filled" margin="normal" />
          <InputLabel id="demo-simple-select-label">Recurrency</InputLabel>
          <FormControl variant="filled" sx={{ mb: 2, minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchEnabled}
              label="Searc Enabled"
              onChange={(e) => updateSearchEnabled(e.target.value)}
            >
              <MenuItem value={'true'}>6</MenuItem>
              <MenuItem value={'false'}>12</MenuItem>
            </Select>
          </FormControl>
          <Button variant="outlined" >Sell Plan</Button>
        </div>
      </div>
    </>
  );
}

export default SellPlan;
