import React from 'react';
import BarberShare from '../../assets/BarberShare.png';
import './header.css';
import { Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Header() {
  return (
      <header className="App-header">
        {/* <AccountCircleIcon fontSize='large' color='info' sx={{fontSize: '60px', marginLeft: '1%'}}/> */}
        <Typography sx={{width: '100%', alignItems: 'center', textAlign: 'center'}}>
                  <img src={BarberShare} className="App-logo" alt="logo" />
        </Typography>
      </header>
  );
}

export default Header;
