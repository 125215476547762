import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import './index.css';
import Home from './views/home/Home';
import reportWebVitals from './config/reportWebVitals';
import Login from './views/login/Login';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
       <Routes>
         <Route>
           <Route path="/" element={<Login />} />
           <Route path="/mvp" element={<Home />} />
         </Route>
       </Routes>
     </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
