import React, { useEffect, useState } from 'react';
import Header from '../../modules/header/header';
import SideBar from '../../modules/sidebar/sidebar';
import Body from '../../modules/body/body';
import './Home.css';

import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ValidateSession } from '../../helpers/validateSession';
import AccountMenu from '../../modules/menu/menu';

function Home() {
  const validateToken = new ValidateSession()
  const [page, setPage] = useState('')

  const navigate = useNavigate();

  function navigateOnPages(page: string){
    setPage(page)
  }

  useEffect(()=>{
    validateSession()
  })

  function checkDevice() { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
       return true;
     }
    else {
       return false;
     }
   }

  function validateSession(){
    const validate = validateToken.validate(localStorage.getItem('stbs')!)
    if(!localStorage.getItem('stbs') || !validate){navigate('/')}
  }

  return (
    <div className="Home">
      <Header />
      <div className="HomeBottom">
        {!checkDevice() && <SideBar navigateOnPages={navigateOnPages}/>}
        {checkDevice() && <AccountMenu navigateOnPages={navigateOnPages}/>}
        <Divider orientation="vertical" variant="middle" flexItem />
        <Body view={page} />
      </div>
    </div>
  );
}

export default Home;
