import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';

export class GenerateUUID {
  private readonly v1options = {
    node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
    clockseq: 0x1234,
    msecs: new Date('2011-11-01').getTime(),
    nsecs: 5678,
  };

  private readonly v4options = {
    random: [
      0x10, 0x91, 0x56, 0xbe, 0xc4, 0xfb, 0xc1, 0xea, 0x71, 0xb4, 0xef, 0xe1, 0x67, 0x1c, 0x58, 0x36,
    ],
  };
  async generatev4(){
    const uuid = await uuidv4(this.v4options);
    return uuid
  }

  async generatev1(){
    const uuid = await uuidv1(this.v1options);
    return uuid
  }
}